<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
		 @clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" clearable placeholder="按订单号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" style="width: 150px;" clearable placeholder="按设备编号查询"></el-input>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="device_id" title="消费设备" align="center" min-width="100px">
				<template v-slot="{ row }">
					<div class="vxe-sub" >编号:{{row.device_no}}-{{row.plug_num}}</div>
				</template>
			</vxe-table-column>

			<vxe-table-column slot="table-item" field="pay_price" title="实际付款金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="electric" title="实际用电量" align="center" width="100px"/>
			<vxe-table-column slot="table-item" field="start_time" title="开始时间" align="center" min-width="100px">
			</vxe-table-column>
            <vxe-table-column slot="table-item" field="end_time" title="结束时间" align="center" min-width="100px">
            </vxe-table-column>
			<vxe-table-column slot="table-item" field="orde_status" title="订单状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.order_status[row.order_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
      <!-- <vxe-table-column slot="table-item" field="coupon_id" title="优惠券" align="center" width="100px"/>-->
			<vxe-table-column slot="table-item" title="操作" align="center" width="230">
				<template v-slot="{ row }">
        <!-- v-if="row.order_status==30&&$hasAccess('cards.order/workorder')" v-if="$hasAccess('user.order/view')"-->
          <el-button size="small" plain @click="detailOne(row)">订单详情</el-button>
          <el-button size="small" plain @click="workOne(row)" >日志</el-button>
				</template>
			</vxe-table-column>
		</Tables>
    <!-- 日志 -->
    <el-dialog title="设备交互日志" :visible.sync="workDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" :fullscreen="true">
      <vxe-table border highlight-hover-row resizable align="center" :data="workDialogFormData">
        <vxe-table-column type="seq" title="#" width="60"></vxe-table-column>
        <vxe-table-column field="time" title="时间" width="160">
          <template v-slot="{ row }">
            <span>{{$common.stampToDatetime(row.time*1000)}}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column field="describe" title="说明" align="left" min-width="300"></vxe-table-column>
        <vxe-table-column field="type" title="传输" width="130" :formatter="formatType"></vxe-table-column>
        <vxe-table-column field="model" title="指令编号" width="85"></vxe-table-column>
        <vxe-table-column field="content" title="源指令" width="340" align="left"></vxe-table-column>
      </vxe-table>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="消费详情" :visible.sync="detailDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="1200px">
      <el-form ref="detailForm" label-width="140px" class="form" size="mini">
        <el-row>
          <el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:1}">
            <!-- 订单信息 -->
            <div class="el-block">
              <div class="row-title">订单信息</div>
              <el-form-item label="订单号：">{{detailDialogFormData.order_no}}</el-form-item>
              <el-form-item label="订单状态：">{{types.order_status[detailDialogFormData.order_status]||'未知状态'}}</el-form-item>
              <el-form-item label="智能充电订单：">{{types.is_auto[detailDialogFormData.is_auto]||'未知'}}</el-form-item>
              <el-form-item label="订单金额：">{{detailDialogFormData.order_price}}元</el-form-item>
              <!-- <el-form-item label="下单时间：">{{detailDialogFormData.creat_time}}</el-form-item> -->
              <el-form-item label="订单时间：">{{detailDialogFormData.start_time}} -<br />{{detailDialogFormData.end_time}}</el-form-item>
              <el-form-item label="订单时长：">{{$common.secondToTime(detailDialogFormData.order_duration*60)}}</el-form-item>
              <el-form-item label="订单功率：">{{detailDialogFormData.order_power}}</el-form-item>
              <el-form-item label="实际充电平均功率：">{{detailDialogFormData.power}}</el-form-item>
              <el-form-item label="实际用电量：">{{detailDialogFormData.electric}}</el-form-item>
              <el-form-item label="实际时长：">{{$common.secondToTime(detailDialogFormData.duration)}}</el-form-item>
              <el-form-item label="账户余额：">{{detailDialogUserBalance}}元</el-form-item>
            </div>
          </el-col>
          <el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
            <!-- 支付信息 -->
            <div class="el-block">
              <div class="row-title">支付信息</div>
              <el-form-item label="消费用户：">{{detailDialogFormData.u_type==10?'微信-'+(detailDialogFormData.nickname||'匿名用户'):'电卡-'+detailDialogFormData.cardno}}</el-form-item>
              <el-form-item v-if="detailDialogFormData.cardno" label="刷卡卡号：">{{detailDialogFormData.cardno}}</el-form-item>
              <el-form-item label="实际支付金额：">{{detailDialogFormData.pay_price}}元</el-form-item>
              <el-form-item label="微信支付金额：">{{detailDialogFormData.wx_price}}元</el-form-item>
              <el-form-item label="支付时间：">{{detailDialogFormData.pay_time}}</el-form-item>
            </div>
            <!-- 设备信息 -->
            <div class="el-block">
              <div class="row-title">设备信息</div>
              <el-form-item label="设备名称：">{{detailDialogFormData.device_name}}<br />{{detailDialogFormData.device_no}}</el-form-item>
              <el-form-item label="插座序号：">{{detailDialogFormData.plug_num}}（ID：{{detailDialogFormData.plug_id}}）</el-form-item>
              <el-form-item label="异常原因：">{{types.unusual_status[detailDialogFormData.unusual_status]||'未知原因'}}</el-form-item>
            </div>
          </el-col>
          <el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
            <!-- 优惠信息 -->
            <div class="el-block">
              <div class="row-title">优惠信息</div>
              <el-form-item label="消费优惠ID：">{{detailDialogFormData.gift_num?detailDialogFormData.gift_id:'无'}}</el-form-item>
              <el-form-item label="优惠数量：">{{detailDialogFormData.gift_num||0}}</el-form-item>
            </div>
            <!-- 代理信息 -->
            <div class="el-block">
              <div class="row-title">代理信息</div>
              <el-form-item label="代理商ID：">{{detailDialogFormData.agent_id||'无'}}</el-form-item>
              <el-form-item label="分销金额：">{{detailDialogFormData.share_price}}元</el-form-item>
              <el-form-item label="分销结算状态：">{{types.share_status[detailDialogFormData.share_status]||'未知状态'}}</el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
        </el-button>
      </div>
    </el-dialog>

	</div>

</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'warehouse-privates',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '消费列表',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					u_type: 10
				},
        types: {
          pay_status: {
            10: '未支付',
            20: '已支付',
            30: '退款',
            50:'无需支付'
          },
          pay_type: {
            10: '钱包',
            20: '电卡',
            30: '先付后充',
            40: '赠送金',
            50:'信用分'
          },
          order_status: {
            10: '进行中',
            20: '已取消',
            30: '已完成'
          },
          unusual_status: {
            10: '正常',
            20: '功率异常',
            30: '插座脱落',
            40: '信号异常'
          },
          is_auto: {
            10: '是',
            20: '否'
          },
          share_status: {
            10: '不结算',
            20: '未结算',
            30: '已结算'
          }
        },
        // 详情
        detailDialogShow: false,
        detailDialogFormData: {},
        detailDialogUserBalance: 0,
        // 日志
        workDialogShow: false,
        workDialogFormData: {},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: []
			}
		},
		methods: {
      // 日志
      async workOne(row) {
        this.isLoading=true;
        const params = {
          token: this.$store.state.user.token,
          order_id: row.order_id,
          type:2
        }
        const res = await this.$api.Fans.GetprivateOrder(params)

        this.workDialogFormData = res.log_content
        this.workDialogShow = true
        this.isLoading=false;
      },
      // 详情
      async detailOne(row) {
        const params = {
          token: this.$store.state.user.token,
          order_id: row.order_id
        }
        const res = await this.$api.Fans.GetPrivateOrderDetail(params);
        this.detailDialogFormData = res;
        console.log(this.detailDialogFormData.unusual_status);
        this.detailUserOne(res.uid)
        this.detailDialogShow = true
      },
      // 用户详情
      async detailUserOne(id) {
        const params = {
          token: this.$store.state.user.token,
          fans_id: id
        }
        const res = await this.$api.Fans.GetFansDetail(params)
        this.detailDialogUserBalance = res.balance
      },

			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				const res = await this.$api.Ware.GetPrivatesList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					u_type: 10
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
			// 列表格式化
			formatType({
				cellValue
			}) {
				if (cellValue == 'server') {
					return '服务器 => 硬件'
				}
				if (cellValue == 'client') {
					return '硬件 => 服务器'
				}
				return '未知'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
